import { configureAxiosWlaHeaders } from "#apis/axios";
import { OrganisationsQueryKeys } from "#apis/organisations/queryKeys";
import { Organisation } from "#customTypes/db";
import { PageContext } from "#customTypes/types";

export const setupClientAxiosHeaders = (pageContext: PageContext) => {
  const wlaOrganisationState = pageContext.dehydratedState?.queries?.find(
    (query) => query.queryHash === JSON.stringify(OrganisationsQueryKeys.wla)
  );

  if (wlaOrganisationState?.state.data) {
    const wlaOrg = wlaOrganisationState.state.data as Organisation;
    configureAxiosWlaHeaders(wlaOrg.organisationId, wlaOrg.domain);
  }
};
